import React, { useEffect } from 'react'

import { useSection } from '@/core/contexts/section'

const Content: React.FunctionComponent = ({ children }) => {
  const { currentSection, setCurrentSection } = useSection()

  const handleScroll = () => {
    const scrollPosition = window.scrollY

    React.Children.toArray(children).forEach((child: any) => {
      const section = document.getElementById(child.props.id)

      if (section) {
        const sectionTop = section.offsetTop
        const sectionBottom = sectionTop + section.offsetHeight
        if (
          scrollPosition >= sectionTop - 25 &&
          scrollPosition < sectionBottom - 25
        ) {
          setCurrentSection(child.props.id)
        }
      }
    })
  }

  useEffect(() => {
    const firstChild = React.Children.toArray(children)[0]
    if (React.isValidElement(firstChild)) {
      setCurrentSection(firstChild.props.id)
    }

    const scrollListener = () => {
      handleScroll()
    }

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return <main className="content">{children}</main>
}

export default Content
